// Created By ND
import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// // context files
// import { AppContext } from "../Context/AppContext";

// for demo call
import AudioVideoCall from "../Pages/CallDemo/AudioVideoCall";

// all components
import Spinner from "../Components/Spinner/spinner.component";
import OfficeSupplies from "../Pages/ReputationModule/officeSupplies/officeSupplies";
import MainIndex from "../Pages/MenuonlineModule/MenuView/mainIndex";
import MenuList from "../Pages/MenuonlineModule/MenuView/MenuList.component";
import InformationSettings from "../Pages/MenuonlineModule/MenuView/InformationSettings";
import CustForm from "../Pages/MenuonlineModule/MenuView/custForm";

// login components
const Signin = React.lazy(() => import("../Pages/Login/Signin.component"));
const Signup = React.lazy(() => import("../Pages/Login/Signup.component"));
const ForgotPassword = React.lazy(
  () => import("../Pages/Login/ForgotPassword.component")
);

// subuserlogin
const SubUserLogin = React.lazy(
  () => import("../Pages/Login/SubUser/SubUserLogin")
);

//logout
const Logout = React.lazy(() => import("../Pages/Login/Logout"));

// redirect from sales
const SwitchPlatformLogin = React.lazy(
  () =>
    import("../Pages/Login/SwitchPlatformLogin/SwitchPlatformLogin.component")
);

// main components
const MainScreen = React.lazy(
  () => import("../Pages/MainScreen/MainScreen.component")
);

// protected route check
const ProtectedRoutes = React.lazy(() => import("./ProtectedRoutes"));

// dashboard components
const HubDashboard = React.lazy(
  () => import("../Pages/HubModules/Dashboard/Dashboard.component")
);

// Marketing
const Marketing = React.lazy(
  () => import("../Pages/HubModules/Marketing/Marketing.component")
);

// Meeting Records
const MeetingRecords = React.lazy(
  () => import("../Pages/Settings/MeetingRecords/MeetingRecords.component")
);

// Reseller Records
const ReDashboard = React.lazy(() => import("../Pages/Reseller/Dashboard"));
const Reseller = React.lazy(
  () => import("../Pages/Reseller/Reseller.component")
);
const OrderDevice = React.lazy(
  () => import("../Pages/Reseller/OrderDevice.component")
);

const Policy = React.lazy(() => import("../Pages/Reseller/policies/index"));

// blast campaign
// const BlastCampaign = React.lazy(
//   () => import("../Pages/HubModules/BlastCampaign/BlastCampaign.component")
// );
// const BlastCampaign = React.lazy(
//   () =>
//     import("../Pages/HubModules/BlastCampaign/BlastCampaign_NewD.component")
// );
const ViewShortLink = React.lazy(
  () =>
    import(
      "../Pages/HubModules/BlastCampaign/components/ViewShortLink/ViewShortLink.component"
    )
);

// auto responder
// const AutoResponder = React.lazy(
//   () => import("../Pages/HubModules/AutoResponder/AutoResponder.component")
// );
// const AutoResponder = React.lazy(
//   () =>
//     import("../Pages/HubModules/AutoResponder/AutoResponder_NewD.component")
// );
const OverViewCampaign = React.lazy(
  () =>
    import(
      "../Pages/HubModules/AutoResponder/Overview/OverViewCampaign.component"
    )
);
const RedeemCoupon = React.lazy(
  () =>
    import(
      "../Pages/HubModules/AutoResponder/RedeemCoupon/RedeemCoupon.component"
    )
);

// bussiness settings
const BusinessSettings = React.lazy(
  () => import("../Pages/Settings/BusinessSettings/BusinessSettings.component")
);
const ChangePassword = React.lazy(
  () => import("../Pages/Settings/ChangePassword/ChangePassword.component")
);
const TermsAndConditions = React.lazy(
  () =>
    import("../Pages/Settings/TermsAndConditions/TermsAndConditions.component")
);
const ViewTermsAndCo = React.lazy(
  () => import("../Pages/Settings/TermsAndConditions/ViewTermsAndCo.componen")
);

// sub users
const Users = React.lazy(
  () => import("../Pages/Settings/SubUsers/SubUsers.component")
);
const AddEditSubUser = React.lazy(
  () =>
    import("../Pages/Settings/SubUsers/AddEditSubUser/AddEditSubUser.component")
);

// custom forms
const CustomForms = React.lazy(
  () => import("../Pages/HubModules/CustomForms/CustomForms.component")
);
const ViewCustomForm = React.lazy(
  () => import("../Pages/HubModules/CustomForms/ViewCustomForm/ViewCustomForm")
);

// widgets
const WebSignUpWidgets = React.lazy(
  () =>
    import(
      "../Pages/HubModules/Widgets/WebSignUpWidgets/WebSignUpWidgets.component"
    )
);
const WebSignupQR = React.lazy(
  () => import("../Pages/HubModules/Widgets/WebSignupQR/WebSignupQR.component")
);
const WebSignupJoinGroup = React.lazy(
  () =>
    import(
      "../Pages/HubModules/Widgets/WebSignupQR/WebSignupJoinGroup.component"
    )
);
const ViewWidgetForm = React.lazy(
  () =>
    import(
      "../Pages/HubModules/Widgets/WebSignUpWidgets/ViewWidgetForm/ViewWidgetForm"
    )
);

// Web SignUp Image
const WebSignUpImage = React.lazy(
  () =>
    import(
      "../Pages/HubModules/Widgets/WebSignUpImage/WebSignUpImage.component"
    )
);

// delivery redirection
const DeliveryRedirection = React.lazy(
  () =>
    import(
      "../Pages/HubModules/DeliveryRedirection/DelliveryRedirection.component"
    )
);
const ViewDeliveryRedirection = React.lazy(
  () =>
    import(
      "../Pages/HubModules/DeliveryRedirection/ViewDeliveryRedirection/ViewDeliveryRedirection.component"
    )
);

// locations
const LocationSettings = React.lazy(
  () => import("../Pages/Settings/Location/LocationSettings.component")
);

// plans
const Plans = React.lazy(
  () => import("../Pages/Settings/Plans/Plans.component")
);

// payments
const Payments = React.lazy(
  () => import("../Pages/Settings/Payments/Payments.component")
);
const CreditCardList = React.lazy(
  () => import("../Pages/Settings/Payments/CreditCardList.component")
);
const AddEditCreditCard = React.lazy(
  () => import("../Pages/Settings/Payments/AddEditCreditCard.component")
);

// clients
const Clients = React.lazy(
  () => import("../Pages/HubModules/Clients/Clients.component")
);
const AddEditClient = React.lazy(
  () => import("../Pages/HubModules/Clients/AddEditClient")
);
const ClientTimeLine = React.lazy(
  () =>
    import(
      "../Pages/HubModules/Clients/ClientTimeLine/ClientTimeLine.component"
    )
);

// groups
const Groups = React.lazy(
  () => import("../Pages/HubModules/Groups/Groups.component")
);
const AddEditGroup = React.lazy(
  () => import("../Pages/HubModules/Groups/AddEditGroup")
);

// tow way chat
const SmsChat = React.lazy(() => import("../Pages/SmsChat/SmsChat"));

// extra components
const DemoComponents = React.lazy(
  () => import("../Pages/DemoComponents/DemoComponents.component")
);

// Error component
const ErrorPage404 = React.lazy(
  () => import("../Pages/ErrorPage/ErrorPage404")
);
const ErrorPage500 = React.lazy(
  () => import("../Pages/ErrorPage/ErrorPage500")
);

// Wifi Modules
// dashboard
const WifiDashboard = React.lazy(
  () => import("../Pages/WifiModule/Dashboard/Dashboard.component")
);

// Hotspot
const Hotspots = React.lazy(
  () => import("../Pages/WifiModule/Hotspot/Hotspots.component")
);
const HotspotInfo = React.lazy(
  () => import("../Pages/WifiModule/Hotspot/HotspotInfo.component")
);
const AddEditHotspot = React.lazy(
  () => import("../Pages/WifiModule/Hotspot/AddEditHotspot")
);

// SplashPage
const SplashPages = React.lazy(
  () => import("../Pages/WifiModule/SplashPage/SplashPage.component")
);
const AddEditSplashPage = React.lazy(
  () => import("../Pages/WifiModule/SplashPage/AddEditSplashPage")
);

// WifiLogin
const WifiLoginMain = React.lazy(
  () => import("../Pages/WifiModule/WifiLogin/WifiLoginMain")
);

// Menu List Routes
const MenuListing = React.lazy(
  () => import("../Pages/MenuonlineModule/MenuList/MenuList.component")
);
const MenuProduct = React.lazy(
  () =>
    import(
      "../Pages/MenuonlineModule/MenuList/MenuProduct/MenuProduct.component"
    )
);
const MenuCustomization = React.lazy(
  () =>
    import(
      "../Pages/MenuonlineModule/MenuList/MenuCustomization/MenuCustomization.component"
    )
);

// Menuonline Routes
const MenuView = React.lazy(
  () => import("../Pages/MenuonlineModule/MenuView/MenuView.component")
);
// Add Menu
const AddMenu = React.lazy(
  () => import("../Pages/MenuonlineModule/MenuView/AddMenu/AddMenu.component")
);

// category module
const Category = React.lazy(
  () => import("../Pages/MenuonlineModule/Category/Category.component")
);

// Add Category
const AddCategory = React.lazy(
  () =>
    import(
      "../Pages/MenuonlineModule/Category/AddCategory/AddCategory.component"
    )
);

// Add Product
const AddProduct = React.lazy(
  () =>
    import("../Pages/MenuonlineModule/Products/AddProduct/AddProduct.component")
);
// Add Variation
const AddVariation = React.lazy(
  () =>
    import(
      "../Pages/MenuonlineModule/MenuView/Variation/AddVariation/AddVariation.component"
    )
);
const Variations = React.lazy(
  () =>
    import("../Pages/MenuonlineModule/MenuView/Variation/Variation.component")
);

// floors
const Floors = React.lazy(
  () => import("../Pages/MenuonlineModule/Floor/Floors.component")
);

// Add Edit floor
const AddEditFloor = React.lazy(
  () => import("../Pages/MenuonlineModule/Floor/AddEditFloor")
);

// Add table
const AddEditTable = React.lazy(
  () => import("../Pages/MenuonlineModule/Table/AddEditTable")
);

// Client Menu
const Reservation = React.lazy(
  () => import("../Pages/MenuonlineModule/Reservation/Reservation.component")
);
//  Add Edit Reservation
const AddEditReservation = React.lazy(
  () => import("../Pages/MenuonlineModule/Reservation/AddEditReservation")
);
const ReservationSettings = React.lazy(
  () =>
    import(
      "../Pages/MenuonlineModule/Reservation/Settings/ReservationSettings.component"
    )
);

// Menu QR
const MenuQR = React.lazy(
  () => import("../Pages/MenuonlineModule/MenuQR/MenuQR.component")
);
const ViewMenuQR = React.lazy(
  () =>
    import("../Pages/MenuonlineModule/MenuQR/ViewMenuQR/ViewMenuQR.component")
);
const ViewReservationQR = React.lazy(
  () =>
    import(
      "../Pages/MenuonlineModule/MenuQR/ViewReservationQR/ViewReservationQR.component"
    )
);

// Client Menu - Home
const Home = React.lazy(
  () => import("../Pages/MenuonlineModule/ClientMenu/Home/Home.component")
);

const MarketPlace = React.lazy(
  () => import("../Pages/MenuonlineModule/MarketPlace/MarketPlace.component")
);

//Reputation module
const RepDashboard = React.lazy(
  () => import("../Pages/ReputationModule/Dashboard/Dashboard.component")
);
const Reputation = React.lazy(
  () => import("../Pages/ReputationModule/Reputation/Reputation.component")
);
const RepFeedbackForm = React.lazy(
  () =>
    import(
      "../Pages/ReputationModule/Reputation/RepFeedbackForm/RepFeedbackForm.component"
    )
);

//Loyalty program module
const LoyaltyProgram = React.lazy(
  () =>
    import(
      "../Pages/LoyaltyProgramModule/LoyaltyProgram/LoyaltyProgram.component"
    )
);
const ProvideStamp = React.lazy(
  () =>
    import(
      "../Pages/LoyaltyProgramModule/LoyaltyProgram/ProvideStamp.component"
    )
);
const ViewLoyaltyProgram = React.lazy(
  () =>
    import(
      "../Pages/LoyaltyProgramModule/ViewLoyaltyProgram/ViewLoyaltyProgram"
    )
);
const ViewLoyaltyUserCard = React.lazy(
  () =>
    import(
      "../Pages/LoyaltyProgramModule/ViewLoyaltyProgram/ViewLoyaltyUserCard"
    )
);

// Policy
const TermsAndService = React.lazy(
  () => import("../Pages/Policy/TermsService.component")
);
const PrivacyPolicy = React.lazy(
  () => import("../Pages/Policy/PrivacyPolicy.component")
);

//Reputation module
function RoutesConfig(props: any) {
  // const { isAuthenticated } = useContext(AppContext);
  return (
    <Routes {...props}>
      {/* Staring Routes */}
      <Route
        element={
          <Suspense fallback={<Spinner />}>
            <ProtectedRoutes isInitial={true} isRoutes={""} />
          </Suspense>
        }
      >
        <Route path="/" element={<Navigate to="/signin" />} />
      </Route>

      <Route
        path="/signin"
        element={
          <Suspense fallback={<Spinner />}>
            <Signin {...props} />
          </Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <Suspense fallback={<Spinner />}>
            <Signup {...props} />
          </Suspense>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Suspense fallback={<Spinner />}>
            <ForgotPassword {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/sub-user-login"
        element={
          <Suspense fallback={<Spinner />}>
            <SubUserLogin {...props} />
          </Suspense>
        }
      />
      <Route
        path="/logout"
        element={
          <Suspense fallback={<Spinner />}>
            <Logout {...props} />
          </Suspense>
        }
      />
      {/* Main Page Routes */}
      <Route
        element={
          <Suspense fallback={<Spinner />}>
            <ProtectedRoutes isMain={true} isRoutes={""} />
          </Suspense>
        }
      >
        <Route
          path="/main"
          element={
            <Suspense fallback={<Spinner />}>
              <MainScreen {...props} />
            </Suspense>
          }
        />
      </Route>

      {/* Marketing Routes */}
      <Route
        element={
          <Suspense fallback={<Spinner />}>
            <ProtectedRoutes isRoutes={"is_marketing_access"} />
          </Suspense>
        }
      >
        <Route
          path="/dashboard"
          element={
            <Suspense fallback={<Spinner />}>
              <HubDashboard {...props} />
            </Suspense>
          }
        />

        <Route
          path="/MeetingRecords"
          element={
            <Suspense fallback={<Spinner />}>
              <MeetingRecords {...props} />
            </Suspense>
          }
        />

        <Route
          path="/marketing"
          element={
            <Suspense fallback={<Spinner />}>
              <Marketing {...props} />
            </Suspense>
          }
        />

        <Route path="/blast-campaign" element={<Navigate to="/marketing" />} />
        {/* <Route
          path="/blast-campaign"
          element={
            <Suspense fallback={<Spinner />}>
              <BlastCampaign {...props} />
            </Suspense>
          }
        /> */}

        <Route path="/auto-responder" element={<Navigate to="/marketing" />} />
        {/* <Route
          path="/auto-responder"
          element={
            <Suspense fallback={<Spinner />}>
              <AutoResponder {...props} />
            </Suspense>
          }
        /> */}
        <Route
          path="/auto-responder/overview"
          // element={<Navigate to="/auto-responder" />}
          element={<Navigate to="/marketing" />}
        />
        <Route
          path="/auto-responder/overview/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <OverViewCampaign {...props} />
            </Suspense>
          }
        />

        <Route
          path="/marketing/custom-forms"
          element={
            <Suspense fallback={<Spinner />}>
              <CustomForms {...props} />
            </Suspense>
          }
        />

        <Route
          path="/marketing/widgets"
          element={
            <Suspense fallback={<Spinner />}>
              <WebSignUpWidgets {...props} />
            </Suspense>
          }
        />
        <Route
          path="/marketing/web-signup-qr"
          element={
            <Suspense fallback={<Spinner />}>
              <WebSignupQR {...props} />
            </Suspense>
          }
        />

        <Route
          path="/marketing/web-signup-image"
          element={
            <Suspense fallback={<Spinner />}>
              <WebSignUpImage {...props} />
            </Suspense>
          }
        />

        <Route
          path="/marketing/delivery-redirection"
          element={
            <Suspense fallback={<Spinner />}>
              <DeliveryRedirection {...props} />
            </Suspense>
          }
        />

        <Route
          path="/*"
          element={
            <Suspense fallback={<Spinner />}>
              <ErrorPage404 {...props} />
            </Suspense>
          }
        />
      </Route>

      {/* user type roots */}
      <Route
        element={
          <Suspense fallback={<Spinner />}>
            <ProtectedRoutes isRoutes={"is_all_users_access"} />
          </Suspense>
        }
      >
        <Route
          path="/clients"
          element={
            <Suspense fallback={<Spinner />}>
              <Clients {...props} />
            </Suspense>
          }
        />
        <Route
          path="/clients/add-client"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditClient {...props} isAdd={true} />
            </Suspense>
          }
        />
        <Route
          path="/clients/edit-client/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditClient {...props} isAdd={false} />
            </Suspense>
          }
        />
        <Route
          path="/clients/timeline/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <ClientTimeLine {...props} />
            </Suspense>
          }
        />
        <Route
          path="/groups"
          element={
            <Suspense fallback={<Spinner />}>
              <Groups {...props} />
            </Suspense>
          }
        />
        <Route
          path="/groups/add-group"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditGroup {...props} isAdd={true} />
            </Suspense>
          }
        />
        <Route
          path="/groups/edit-group/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditGroup {...props} isAdd={false} />
            </Suspense>
          }
        />

        <Route
          path="/*"
          element={
            <Suspense fallback={<Spinner />}>
              <ErrorPage404 {...props} />
            </Suspense>
          }
        />
      </Route>

      <Route
        element={
          <Suspense fallback={<Spinner />}>
            <ProtectedRoutes isRoutes={"is_main_users_access"} />
          </Suspense>
        }
      >
        <Route
          path="/account-settings"
          element={
            <Suspense fallback={<Spinner />}>
              <BusinessSettings {...props} />
            </Suspense>
          }
        />

        <Route
          path="/plans"
          element={
            <Suspense fallback={<Spinner />}>
              <Plans {...props} />
            </Suspense>
          }
        />

        <Route
          path="/marketplace"
          element={
            <Suspense fallback={<Spinner />}>
              <MarketPlace {...props} />
            </Suspense>
          }
        />

        <Route
          path="/payments"
          element={
            <Suspense fallback={<Spinner />}>
              <Payments {...props} />
            </Suspense>
          }
        />
        <Route
          path="/payments/credit-cards"
          element={
            <Suspense fallback={<Spinner />}>
              <CreditCardList {...props} />
            </Suspense>
          }
        />
        <Route
          path="/payments/credit-cards/add-credit-card"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditCreditCard {...props} isAdd={true} />
            </Suspense>
          }
        />
        <Route
          path="/payments/credit-cards/edit-credit-card/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditCreditCard {...props} isAdd={false} />
            </Suspense>
          }
        />
        <Route
          path="/location-settings"
          element={
            <Suspense fallback={<Spinner />}>
              <LocationSettings {...props} />
            </Suspense>
          }
        />
        <Route
          path="/users"
          element={
            <Suspense fallback={<Spinner />}>
              <Users {...props} />
            </Suspense>
          }
        />
        <Route
          path="/users/add-sub-user"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditSubUser {...props} isAdd={true} />
            </Suspense>
          }
        />
        <Route
          path="/users/edit-sub-user/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditSubUser {...props} isAdd={false} />
            </Suspense>
          }
        />
        <Route
          path="/change-password"
          element={
            <Suspense fallback={<Spinner />}>
              <ChangePassword {...props} />
            </Suspense>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Suspense fallback={<Spinner />}>
              <TermsAndConditions {...props} />
            </Suspense>
          }
        />
        <Route
          path="/sms-chat"
          element={
            <Suspense fallback={<Spinner />}>
              <SmsChat {...props} />
            </Suspense>
          }
        />
        <Route
          path="/*"
          element={
            <Suspense fallback={<Spinner />}>
              <ErrorPage404 {...props} />
            </Suspense>
          }
        />
      </Route>

      {/* Reseller Routes */}
      <Route
        element={
          <Suspense fallback={<Spinner />}>
            <ProtectedRoutes isRoutes={"is_reseller"} />
          </Suspense>
        }
      >
        <Route
          path="/res-dashboard"
          element={
            <Suspense fallback={<Spinner />}>
              <ReDashboard {...props} />
            </Suspense>
          }
        />

        <Route
          path="/reseller"
          element={
            <Suspense fallback={<Spinner />}>
              <Reseller {...props} />
            </Suspense>
          }
        />
        <Route
          path="/orderDevice"
          element={
            <Suspense fallback={<Spinner />}>
              <OrderDevice {...props} />
            </Suspense>
          }
        />
        <Route
          path="/policies"
          element={
            <Suspense fallback={<Spinner />}>
              <Policy {...props} />
            </Suspense>
          }
        />
      </Route>

      {/* without authentication url/ public access url */}
      <Route
        path="/s"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />

      <Route
        path="/s/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <ViewShortLink {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/cf"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/cf/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <ViewCustomForm {...props} />
          </Suspense>
        }
      />

      <Route
        path="/:domainname/wjg"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/wjg/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <WebSignupJoinGroup {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/wsw"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/wsw/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <ViewWidgetForm {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/rp"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/rp/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <RepFeedbackForm {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vdr"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vdr/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <ViewDeliveryRedirection {...props} />
          </Suspense>
        }
      />
      <Route
        path="/switch_platform"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/call"
        element={
          <Suspense fallback={<Spinner />}>
            <AudioVideoCall {...props} />
          </Suspense>
        }
      />
      <Route
        path="/switch_platform/:domainname/:id/:usertype"
        element={
          <Suspense fallback={<Spinner />}>
            <SwitchPlatformLogin {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vrr"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vrr/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <RedeemCoupon {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vbc"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vbc/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <RedeemCoupon {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/wid"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/wid/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <RedeemCoupon {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/drc"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/drc/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <RedeemCoupon {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/view-terms-and-conditions/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <ViewTermsAndCo {...props} />
          </Suspense>
        }
      />

      <Route
        path="/terms-service"
        element={
          <Suspense fallback={<Spinner />}>
            <TermsAndService {...props} />
          </Suspense>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Suspense fallback={<Spinner />}>
            <PrivacyPolicy {...props} />
          </Suspense>
        }
      />

      {/* Wifi Routes */}
      <Route
        element={
          <Suspense fallback={<Spinner />}>
            <ProtectedRoutes isRoutes={"is_wifi_access"} />
          </Suspense>
        }
      >
        <Route
          path="/wifi-dashboard"
          element={
            <Suspense fallback={<Spinner />}>
              <WifiDashboard {...props} />
            </Suspense>
          }
        />
        <Route
          path="/hotspots"
          element={
            <Suspense fallback={<Spinner />}>
              <Hotspots {...props} />
            </Suspense>
          }
        />
        <Route
          path="/hotspots/hotspot-info/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <HotspotInfo {...props} />
            </Suspense>
          }
        />
        <Route
          path="/hotspots/add-hotspot"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditHotspot {...props} isAdd={true} />
            </Suspense>
          }
        />
        <Route
          path="/hotspots/edit-hotspot/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditHotspot {...props} isAdd={false} />
            </Suspense>
          }
        />
        <Route
          path="/splashpages"
          element={
            <Suspense fallback={<Spinner />}>
              <SplashPages {...props} />
            </Suspense>
          }
        />
        <Route
          path="/splashpages/add-splashpage"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditSplashPage {...props} isAdd={true} />
            </Suspense>
          }
        />
        <Route
          path="/splashpages/edit-splashpage/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditSplashPage {...props} isAdd={false} />
            </Suspense>
          }
        />

        <Route
          path="/*"
          element={
            <Suspense fallback={<Spinner />}>
              <ErrorPage404 {...props} />
            </Suspense>
          }
        />
      </Route>

      {/* without authentication url/ public access url */}
      <Route
        path="/:domainname/wl"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/wl/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <WifiLoginMain {...props} isPreview={true} />
          </Suspense>
        }
      />
      <Route
        path="/splash"
        element={
          <Suspense fallback={<Spinner />}>
            <WifiLoginMain {...props} />
          </Suspense>
        }
      />

      {/* Menuonline Routes */}
      <Route
        element={
          <Suspense fallback={<Spinner />}>
            <ProtectedRoutes isRoutes={"is_menu_online_access"} />
          </Suspense>
        }
      >
        <Route
          path="/reservation"
          element={
            <Suspense fallback={<Spinner />}>
              <Reservation {...props} />
            </Suspense>
          }
        />
        <Route
          path="/reservation/res-settings"
          element={
            <Suspense fallback={<Spinner />}>
              <ReservationSettings {...props} />
            </Suspense>
          }
        />
        <Route
          path="/reservation/add-reservation"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditReservation {...props} isAdd={true} isRoute={true} />
            </Suspense>
          }
        />
        <Route
          path="/reservation/edit-reservation/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditReservation {...props} isAdd={false} isRoute={true} />
            </Suspense>
          }
        />
        <Route
          path="/floors"
          element={
            <Suspense fallback={<Spinner />}>
              <Floors {...props} />
            </Suspense>
          }
        />
        <Route
          path="/floors/add-floor"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditFloor {...props} isAdd={true} />
            </Suspense>
          }
        />
        <Route
          path="/floors/edit-floor/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditFloor {...props} isAdd={false} />
            </Suspense>
          }
        />
        <Route
          path="/floors/add-table/:floor_id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditTable {...props} isAdd={true} />
            </Suspense>
          }
        />
        <Route
          path="/floors/edit-table/:floor_id/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddEditTable {...props} isAdd={false} />
            </Suspense>
          }
        />
        <Route
          path="/menu"
          element={
            <Suspense fallback={<Spinner />}>
              {/* <MenuView {...props} /> */}
              {/* <MainIndex {...props} /> */}
              <MenuList {...props} />
            </Suspense>
          }
        />

        <Route
          path="/menu/add-menu"
          element={
            <Suspense fallback={<Spinner />}>
              <AddMenu {...props} />
            </Suspense>
          }
        />
        <Route
          path="/menu/category/:menu_id"
          element={
            <Suspense fallback={<Spinner />}>
              <Category {...props} />
            </Suspense>
          }
        />
        <Route
          path="/menu/add-category/:menu_id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddCategory {...props} />
            </Suspense>
          }
        />
        <Route
          path="/menu/add-product/:menu_id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddProduct {...props} isAdd={true} />
            </Suspense>
          }
        />
        <Route
          path="/menu/edit-product/:menu_id/:id"
          element={
            <Suspense fallback={<Spinner />}>
              <AddProduct {...props} isAdd={false} />
            </Suspense>
          }
        />
        {/* variations */}
        <Route
          path="/menu/variations"
          element={
            <Suspense fallback={<Spinner />}>
              <Variations {...props} isAdd={false} />
            </Suspense>
          }
        />
        <Route
          path="/menu/add-variations"
          element={
            <Suspense fallback={<Spinner />}>
              <AddVariation {...props} isAdd={false} />
            </Suspense>
          }
        />
        <Route
          path="/menu-qr"
          element={
            <Suspense fallback={<Spinner />}>
              <MenuQR {...props} />
            </Suspense>
          }
        />

        <Route
          path="/menulist"
          element={
            <Suspense fallback={<Spinner />}>
              <MenuListing {...props} />
            </Suspense>
          }
        />
        <Route
          path="/menulist/category/:menu_id"
          element={
            <Suspense fallback={<Spinner />}>
              <MenuProduct {...props} />
            </Suspense>
          }
        />
        <Route
          path="/menulist/category/:menu_id/:cat_id"
          element={
            <Suspense fallback={<Spinner />}>
              <MenuProduct {...props} />
            </Suspense>
          }
        />
        <Route
          path="/menulist/category/customization/:menu_id"
          element={
            <Suspense fallback={<Spinner />}>
              <MenuCustomization {...props} />
            </Suspense>
          }
        />
        <Route
          path="/*"
          element={
            <Suspense fallback={<Spinner />}>
              <ErrorPage404 {...props} />
            </Suspense>
          }
        />
      </Route>

      {/* public access url */}
      <Route
        path="/:domainname/vmq"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vmq/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <ViewMenuQR {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/arlq"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/arlq/:id/:locationname"
        element={
          <Suspense fallback={<Spinner />}>
            <ViewReservationQR {...props} />
          </Suspense>
        }
      />

      <Route
        path="/:domainname/home"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainName/home/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <Home {...props} />
          </Suspense>
        }
      />

      {/* Demo Component */}
      <Route
        path="/demo"
        element={
          <Suspense fallback={<Spinner />}>
            <DemoComponents {...props} />
          </Suspense>
        }
      />

      {/* internal server error */}
      <Route
        path="/error"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage500 {...props} />
          </Suspense>
        }
      />

      {/* Reputation Routes */}
      <Route
        element={
          <Suspense fallback={<Spinner />}>
            <ProtectedRoutes isRoutes={"is_reputation_access"} />
          </Suspense>
        }
      >
        <Route
          path="/rep-dashboard"
          element={
            <Suspense fallback={<Spinner />}>
              <RepDashboard {...props} />
            </Suspense>
          }
        />
        <Route
          path="/reputation"
          element={
            <Suspense fallback={<Spinner />}>
              <Reputation {...props} />
            </Suspense>
          }
        />
        <Route
          path="/officeSupplies"
          element={
            <Suspense fallback={<Spinner />}>
              <OfficeSupplies {...props} />
            </Suspense>
          }
        />
      </Route>

      {/* Loyalty Program Routes */}
      <Route
        element={
          <Suspense fallback={<Spinner />}>
            <ProtectedRoutes isRoutes={"is_loyalty_program_access"} />
          </Suspense>
        }
      >
        <Route
          path="/loyalty-program"
          element={
            <Suspense fallback={<Spinner />}>
              <LoyaltyProgram {...props} />
            </Suspense>
          }
        />
        <Route
          path="/add-stamp"
          element={
            <Suspense fallback={<Spinner />}>
              <ProvideStamp {...props} />
            </Suspense>
          }
        />
      </Route>

      {/* public access url */}
      <Route
        path="/:domainname/vrc"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vrc/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <RedeemCoupon {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vlp"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vlp/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <ViewLoyaltyProgram {...props} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vlc"
        element={
          <Suspense fallback={<Spinner />}>
            <ErrorPage404 {...props} isHide={true} />
          </Suspense>
        }
      />
      <Route
        path="/:domainname/vlc/:id"
        element={
          <Suspense fallback={<Spinner />}>
            <ViewLoyaltyUserCard {...props} />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default RoutesConfig;
